.loading-overlay {
  display: flex;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.loading-overlay.loading-inprogress {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.5s linear;
}

.loading-overlay.loading-done {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.5s, opacity 0.5s linear;
}
