
div, body {
  /*scrollbar-color: #6b6b6b #2b2b2b;*/
  scrollbar-color: var(--spectrum-global-color-gray-400) transparent;
}

::-webkit-scrollbar {
  width: 14px;
  background-color: transparent;
}

::-webkit-scrollbar-corner {
  background-color:transparent;
}

::-webkit-scrollbar-track:hover {
  background-color: rgba(0,0,0,0.05);
}

/*::-webkit-scrollbar-track:vertical {
  border-width: 0px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-style: solid;
  border-color: transparent;
}

::-webkit-scrollbar-track:vertical:hover {
  border-color: var(--spectrum-global-color-gray-200);
}

::-webkit-scrollbar-track:horizontal {
  border-width: 0px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: transparent;
}

::-webkit-scrollbar-track:horizontal:hover {
  border-color: var(--spectrum-global-color-gray-200);
}*/

::-webkit-scrollbar-thumb:vertical {
  background: linear-gradient(90deg, transparent 3px,var(--spectrum-global-color-gray-400) 3px, var(--spectrum-global-color-gray-400) 11px, transparent 11px);
  border-radius: 15px;
}
     
::-webkit-scrollbar-thumb:vertical:hover {
  background: linear-gradient(90deg, transparent 3px,var(--spectrum-global-color-gray-600) 3px, var(--spectrum-global-color-gray-600) 11px, transparent 11px);
}

::-webkit-scrollbar-thumb:horizontal {
  background: linear-gradient(0deg, transparent 3px,var(--spectrum-global-color-gray-400) 3px, var(--spectrum-global-color-gray-400) 11px, transparent 11px);
  border-radius: 15px;
}

::-webkit-scrollbar-thumb:horizontal:hover {
  background: linear-gradient(0deg, transparent 3px,var(--spectrum-global-color-gray-600) 3px, var(--spectrum-global-color-gray-600) 11px, transparent 11px);
}
