/* font-style: normal */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local('Source Sans Pro ExtraLight'), url('./fonts/SourceSansPro-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Source Sans Pro Light'), url('./fonts/SourceSansPro-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Source Sans Pro Regular'), url('./fonts/SourceSansPro-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Source Sans Pro SemiBold'), url('./fonts/SourceSansPro-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Source Sans Pro Bold'), url('./fonts/SourceSansPro-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Source Sans Pro Bold'), url('./fonts/SourceSansPro-Black.ttf') format('truetype');
}

/* font-style: italic */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: local('Source Sans Pro ExtraLight Italic'), url('./fonts/SourceSansPro-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local('Source Sans Pro Light Italic'), url('./fonts/SourceSansPro-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Source Sans Pro Italic'), url('./fonts/SourceSansPro-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: local('Source Sans Pro SemiBold Italic'), url('./fonts/SourceSansPro-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Source Sans Pro Bold Italic'), url('./fonts/SourceSansPro-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: local('Source Sans Pro Bold Italic'), url('./fonts/SourceSansPro-BlackItalic.ttf') format('truetype');
}
