.timeline * {
  box-sizing: border-box;
}

.timeline *::before, .timeline *::after {
  box-sizing: border-box;
}

.timeline-title {
  font-size: 1.1428571428571428em;
  font-weight: bold;
}

.timeline-item.period .timeline-title {
  font-size: 1.2857142857142858em;
}

.timeline {
  line-height: 1.4em !important;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.timeline-item {
  padding-left: 40px;
  position: relative;
}
.timeline-item:last-child {
  padding-bottom: 0;
}

.timeline-info {
  font-size: 0.8571428571428571em !important;
  font-weight: 700;
  letter-spacing: 0.16666666666666666em;
  margin: 0 0 0.5em 0;
  text-transform: uppercase;
  white-space: nowrap;
}

.timeline-marker {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15px;
}
.timeline-marker:before {
  background: var(--spectrum-global-color-red-400);
  border: 3px solid transparent;
  border-radius: 100%;
  content: "";
  display: block;
  height: 15px;
  position: absolute;
  top: 4px;
  left: 0;
  width: 15px;
  transition: background 0.3s ease-in-out, border 0.3s ease-in-out;
}
.timeline-marker:after {
  content: "";
  width: 3px;
  background: #CCD5DB;
  display: block;
  position: absolute;
  top: 24px;
  bottom: 0;
  left: 6px;
}
.timeline-item:last-child .timeline-marker:after {
  content: none;
}

.timeline-item:not(.period):hover .timeline-marker:before {
  background: transparent;
  border: 3px solid var(--spectrum-global-color-red-400);
}

.timeline-content {
  padding-bottom: 40px;
}
.timeline-content p:last-child {
  margin-bottom: 0;
}

.period {
  padding: 0;
}
.period .timeline-info {
  display: none;
}
.period .timeline-marker:before {
  background: transparent;
  content: "";
  width: 15px;
  height: auto;
  border: none;
  border-radius: 0;
  top: 0;
  bottom: 30px;
  position: absolute;
  border-top: 3px solid #CCD5DB;
  border-bottom: 3px solid #CCD5DB;
}
.period:first-child .timeline-marker:before {
  background: transparent;
  content: "";
  width: 15px;
  height: auto;
  border: none;
  border-radius: 0;
  top: 0;
  bottom: 30px;
  position: absolute;
  border-top: 0px solid #CCD5DB;
  border-bottom: 3px solid #CCD5DB;
}
.period .timeline-marker:after {
  content: "";
  height: 32px;
  top: auto;
}
.period .timeline-content {
  padding: 20px 0 50px;
}
.period .timeline-title {
  margin: 0;
}

/*
 * Split
 */
.timeline-split .timeline {
  display: table;
}
.timeline-split .timeline-item {
  display: table-row;
  padding: 0;
}

.timeline-split .timeline-info,
.timeline-split .timeline-marker,
.timeline-split .timeline-content,
.timeline-split .period .timeline-info {
  display: table-cell;
  vertical-align: top;
}
.timeline-split .timeline-marker {
  position: relative;
}
.timeline-split .timeline-content {
  padding-left: 30px;
}
.timeline-split .timeline-info {
  padding-right: 30px;
}
.timeline-split .period .timeline-title {
  position: relative;
  left: -45px;
}

/*
 * Centered
 */
.timeline-centered,
.timeline-centered .timeline-item,
.timeline-centered .timeline-info,
.timeline-centered .timeline-marker,
.timeline-centered .timeline-content {
  display: block;
  margin: 0;
  padding: 0;
}
.timeline-centered .timeline-item {
  padding-bottom: 40px;
  overflow: hidden;
}
.timeline-centered .timeline-marker {
  position: absolute;
  left: 50%;
  margin-left: -7.5px;
}
.timeline-centered .timeline-info,
.timeline-centered .timeline-content {
  width: 50%;
}
.timeline-centered > .timeline-item:nth-child(odd) .timeline-info {
  float: left;
  text-align: right;
  padding-right: 30px;
}
.timeline-centered > .timeline-item:nth-child(odd) .timeline-content {
  float: right;
  text-align: left;
  padding-left: 30px;
}
.timeline-centered > .timeline-item:nth-child(even) .timeline-info {
  float: right;
  text-align: left;
  padding-left: 30px;
}
.timeline-centered > .timeline-item:nth-child(even) .timeline-content {
  float: left;
  text-align: right;
  padding-right: 30px;
}
.timeline-centered > .timeline-item.period .timeline-content {
  float: none;
  padding: 0;
  width: 100%;
  text-align: center;
}
.timeline-centered .timeline-item.period {
  padding: 30px 0 60px;
}
.timeline-centered .period .timeline-marker:after {
  height: 30px;
  bottom: 0;
  top: auto;
}
.timeline-centered .period .timeline-title {
  left: auto;
}